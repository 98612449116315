import { Component, Input, Output, EventEmitter, Renderer2, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { LocalStorageService } from 'framework/localstorage.service';
import * as dateUtils from 'framework/utils/date.utils';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from 'framework/utils/util.service';
import { EmploymentService } from '../employment.service';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { TranslateService } from 'framework/i18n';

declare var $: any;

@Component({
  selector: 'multiple-position',
  templateUrl: './multiple-position.component.html',
  styleUrls: ['./multiple-position.component.css']
})
export class MultiplePositionComponent implements OnInit {

  _data = new BehaviorSubject<any>([]);
  _validPositionData = new BehaviorSubject<any>([]);
  _currentRowData = new BehaviorSubject<any>([]);
  _addNewPosition = new BehaviorSubject<any>([]);


  @Input('group')
  public empSubForm: FormGroup;

  @Input() row: any;

  @Input() cmSelectedDate: any;
  @Input() configOptions: any;
  @Input() exiForm: any;
  @Input() empType: any;

  @Input() editInd: any;
  @Input() showEndDate: any;

  @Input() updateMultiPositionModal: number;
  @Input() isEmploymentGapInDaysEnabled: boolean;

  @Input()
  set currentEditableObj(value) {
    this._data.next(value);
  };

  get currentEditableObj() {
    return this._data.getValue();
  }

  @Input()
  set currentRowPositionList(value) {
    this._currentRowData.next(value);
  };

  get currentRowPositionList() {
    return this._currentRowData.getValue();
  }

  @Input()
  set allPositionList(value) {
    this._validPositionData.next(value);
  };

  get allPositionList() {
    return this._validPositionData.getValue();
  }

  @Input()
  set addNewPosition(value) {
    this._addNewPosition.next(value);
  };

  get addNewPosition() {
    return this._addNewPosition.getValue();
  }


  @Output() onAddPosition: EventEmitter<any> = new EventEmitter();
  @Output() onEditPosition: EventEmitter<any> = new EventEmitter();
  @Output() onValidatePosition: EventEmitter<any> = new EventEmitter();
  @Output() onCriminalSetDateSwipe: EventEmitter<any> = new EventEmitter();


  isValidDate;
  dateErrFlag;
  showDateErr;
  firstName;
  date: any;
  fromSetDate: Date;
  startDate: any;
  endDate: any;
  validEmpPosition: boolean = true;
  multiplePositionArr: any[];
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  endDateLessThanStartDate: boolean = false;
  endDateExist: boolean = false;
  startDateExist: boolean = false;
  validateDates: boolean = false;
  currentEditEmpPosition: string;

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  currentStatus: string = 'VISITED';
  payLoad: Object;
  selectedDate: Date;
  monthNames;

  constructor(private localStorage: LocalStorageService, private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _utilService: UtilService,
    private _es: EmploymentService,
    private _ts: TranslateService) {
      this.monthNames = this._ts.instant('MONTH_NAMES');
  }


  ngOnInit() {

    this._data
      .subscribe(x => {
        this.setFormValues(x);
      });

    this._validPositionData.subscribe(res => {
      if (res !== undefined) {
        this.multiplePositionArr = res;
      } else {
        this.multiplePositionArr = [];
      }
    });

    this._currentRowData.subscribe(res => {

    });

    this._addNewPosition.subscribe(res => {
      this.setFieldsForNewPosition(res);
    });


  }

  ngAfterViewInit() {
    this.processConfig();
  }

  ngOnChanges() {

    this._validPositionData.subscribe(res => {
      if (res !== undefined) {
        this.multiplePositionArr = res;
      } else {
        this.multiplePositionArr = [];
      }
    });
  }
  private setFormValues(obj) {
    this.setPositionValue(obj);
    this.setDateValues(obj);
  }

  private setPositionValue(obj) {
    if (!this._utilService.isEmpty(obj['empPosition'])) {
      this.empSubForm.controls['empPosition'].setValue(obj['empPosition']);
    } else {
      this.empSubForm.controls['empPosition'].setValue('');
    }
  }

  private setDateValues(obj) {
    if (!this._utilService.isEmpty(obj['intlStartDate'])) {
      this.empSubForm.controls['intlStartDate'].setValue(obj['intlStartDate']);
      if (this.isEmploymentGapInDaysEnabled) {
        this.startDate = dateUtils.getFullDate(obj['intlStartDate'], this.monthNames);
      } else {
        this.startDate = dateUtils.getDate(obj['intlStartDate'], this.monthNames);
      }
    } else {
      this.empSubForm.controls['intlStartDate'].setValue('');
      this.startDate = null;
    }

    if (!this._utilService.isEmpty(obj['intlEndDate'])) {
      this.empSubForm.controls['intlEndDate'].setValue(obj['intlEndDate']);
      if (this.isEmploymentGapInDaysEnabled) {
        this.endDate = dateUtils.getFullDate(obj['intlEndDate'], this.monthNames);
      } else {
        this.endDate = dateUtils.getDate(obj['intlEndDate'], this.monthNames);
      }
    } else {
      this.empSubForm.controls['intlEndDate'].setValue('');
      this.endDate = null;
    }
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };


  }

  /**
   * validate start/ end date of position
   */
  private validateDate() {
    this.isValidStartDate = true;
    this.isValidEndDate = true;
    this.endDateLessThanStartDate = false;
    this.endDateExist = false;
    this.endDateExist = false;
    this.validateDates = true;

    if (!this._utilService.isEmpty(this.multiplePositionArr)) {
      for (let index = 0; !this._utilService.isEmpty(this.multiplePositionArr[index]) && this.multiplePositionArr[index].length > 0; index++) {
        let positionList = this.multiplePositionArr[index];
        let i = 0;
        for (let position of positionList) {
          if (this.row === index && i === this.currentEditableObj['indexOfMultiPos']) {
            continue;
          }
          // continue validation if all the fields are not false
          if (!(!this.isValidStartDate && !this.isValidEndDate)) {
            if (this._utilService.isEmpty(positionList[i]['status']) || positionList[i]['status']) {

              if (this.validateDates) {
                this.validateStartAndEndDate(positionList[i]['intlStartDate'], positionList[i]['intlEndDate']);
              }
            }
          }

          i++;
        }
      }
    }
    this.validateDates = false;

  }

  /**
   * validates the start and end date of positions
   */
  private validateStartAndEndDate(existingStartDateValue, existingEndDateValue) {

    const startDatevalue = this.empSubForm.controls['intlStartDate'].value;
    const endDatevalue = this.empSubForm.controls['intlEndDate'].value;
    // check entered start date is not empty
    if (this._utilService.isEmpty(startDatevalue)) {
      this.isValidStartDate = false;
    }
    // check entered end date is not empty
    if (this._utilService.isEmpty(endDatevalue) && this.showEndDate) {
      this.isValidEndDate = false;
    }
    // check end date is present or not. no need to validate dates if it is yes
    if (!this._utilService.isEmpty(endDatevalue) && endDatevalue === 'Present') {
      this.isValidEndDate = true;
      this.isValidStartDate = true;
      return;
    }

    if (this._utilService.isEmpty(endDatevalue) && !this.showEndDate) {
      return;
    }

    this.afterValidateStartAndEndDate(startDatevalue, endDatevalue, existingStartDateValue, existingEndDateValue);

  }

  private afterValidateStartAndEndDate(startDatevalue, endDatevalue, existingStartDateValue, existingEndDateValue) {
    if (this.isValidStartDate && this.isValidEndDate) {
      let startDate;
      let endDate;
      if (this.isEmploymentGapInDaysEnabled) {
        startDate = dateUtils.getFullDate(startDatevalue, this.monthNames);
        endDate = dateUtils.getFullDate(endDatevalue, this.monthNames);
      } else {
        startDate = dateUtils.getDate(startDatevalue, this.monthNames);
        endDate = dateUtils.getDate(endDatevalue, this.monthNames);
      }
      //  check entered start date should not be greater than or equal to entered end date
      if (startDate.getTime() >= endDate.getTime()) {
        this.endDateLessThanStartDate = true;
        this.isValidEndDate = false;

      }

      // validate with already captured start/end dates
      //  let existingStartDate = dateUtils.getDate(existingStartDateValue, this._es.monthNames, this.monthNames);
      // set existing end date as today's date if it is present
      //  let existingEndDate = new Date();
      //  existingEndDate.setDate(1);
      //  existingEndDate.setHours(0, 0, 0, 0);
      //  if (existingEndDateValue !== 'Present') {
      //    existingEndDate = dateUtils.getDate(existingEndDateValue, this._es.monthNames, this.monthNames);
      //  }

      // check entered start date should not be in between existing start and end date
      //  if (startDate >= existingStartDate && startDate < existingEndDate) {
      //    this.startDateExist = true;
      //    this.isValidStartDate = false;
      //  }
      // check entered end date should not be in between existing start and end date
      //  if (endDate > existingStartDate && endDate <= existingEndDate) {
      //this.endDateExist = true;
      //this.isValidEndDate = false;
      //  }
    }
  }

  
  addPosition(obj) {

    this.validateDates = true;
    this.validateDate();
    if (this.isValidStartDate && this.isValidEndDate) {
      this.onAddPosition.emit(obj);
    }

  }

  getFromDpValue(value): void {
    this.isValidStartDate = true;
    if (this.isEmploymentGapInDaysEnabled) {
      this.startDate = dateUtils.getFullDate(value, this.monthNames);
    } else {
      this.startDate = dateUtils.getDate(value, this.monthNames);
    }
    (<FormControl>this.empSubForm.controls['intlStartDate']).setValue(value);

  }

  getToDpValue(value): void {
    this.isValidEndDate = true;
    if (this.isEmploymentGapInDaysEnabled) {
      this.endDate = dateUtils.getFullDate(value, this.monthNames);
    } else {
      this.endDate = dateUtils.getDate(value, this.monthNames);
    }
    this.setControlDateValue(value);
  }

  private setControlDateValue(value) {
    if (this.empSubForm.controls['intlEndDate'] !== undefined) {
      (<FormControl>this.empSubForm.controls['intlEndDate']).setValue(value);

    }

    if (this.empSubForm.controls['jobTo'] !== undefined) {
      (<FormControl>this.empSubForm.controls['jobTo']).setValue(value);
    }
  }

  
  editPosition(obj) {
    this.currentEditEmpPosition = this.currentEditableObj['empPosition'];

    this.validateDate();
    if (obj['intlStartDate'] !== undefined && obj['intlStartDate'] !== '') {
      if (this.isValidStartDate && this.isValidEndDate) {
        this.onEditPosition.emit(obj);
      }
    }
  }


  closeStartDateError() {
    this.isValidStartDate = true;

  }

  closeEndDateError() {
    this.isValidEndDate = true;

  }

  /**
   * reset fields for add new position
   */

  private setFieldsForNewPosition(addNewPosition) {
    if (addNewPosition) {
      this.empSubForm.controls['empPosition'].setValue('');
      //setting the endDate to Existing StartDate
      this.empSubForm.controls['intlEndDate'].setValue(this.currentEditableObj.intlEndDate);
      this.empSubForm.controls['intlStartDate'].setValue(this.currentEditableObj['intlStartDate']);

      if (this.isEmploymentGapInDaysEnabled) {
        this.endDate = (this.currentEditableObj.intlEndDate) ? dateUtils.getFullDate(this.currentEditableObj.intlEndDate, this.monthNames) : null;
        this.startDate = (this.currentEditableObj.intlStartDate) ? dateUtils.getFullDate(this.currentEditableObj.intlStartDate, this.monthNames) : null;
      } else {
        this.endDate = (this.currentEditableObj.intlEndDate) ? dateUtils.getDate(this.currentEditableObj.intlEndDate, this.monthNames) : null;
        this.startDate = (this.currentEditableObj.intlStartDate) ? dateUtils.getDate(this.currentEditableObj.intlStartDate, this.monthNames) : null;
      }
     }
  }

  get isAddPositionDisabled() {
    return !(this.empSubForm.controls['empPosition'].valid && this.empSubForm.controls['intlStartDate'].valid && !(this.showEndDate && !this.empSubForm.controls['intlEndDate'].valid) &&
      !(this.empType !== 'current' && this.empSubForm.controls['intlEndDate'].value === 'Present'));
  }

  get isEditPositionDisabled() {
    return !this.empSubForm.controls['empPosition'].valid;
  }

}
